import { ImageLoaderProps } from 'next/dist/client/legacy/image'
import { ImageType } from '@/src/@types/statamic'

export default function loader({ width, src, quality }: ImageLoaderProps) {
  if (!src) return ''
  if (src.includes('.gif')) {
    return `https://res.cloudinary.com/blnk/image/upload/f_auto,q_${
      quality || 85
    }/${src}`
  } else {
    return `https://res.cloudinary.com/blnk/image/upload/f_auto,q_${
      quality || 85
    },w_${width}/${src}`
  }
}

export function blurImage(src: string) {
  return `https://res.cloudinary.com/blnk/image/upload/f_auto,q_1,w_16,e_blur:300/${src}`
}

export function socialImageUrl(image: ImageType) {
  if (!image.permalink) {
    return ''
  }

  const path = getCloudinaryPath(image.permalink)

  if (!image.focus_css) {
    return `https://res.cloudinary.com/blnk/image/upload/f_auto,q_75,w_1200,/${path}`
  }

  // convert 50%, 50% to [0.5, 0.5]
  const [x, y] = image.focus_css.split(' ').map((p) => parseFloat(p) / 100)

  // this is the default therefore we rather use ai to get the best result
  if (x === 0.5 && y === 0.5) {
    return `https://res.cloudinary.com/blnk/image/upload/f_auto,q_75,w_1200,h_630,g_auto,c_fill/${path}`
  }

  return `https://res.cloudinary.com/blnk/image/upload/f_auto,q_75,w_1200,h_630,x_${x},y_${y},c_crop/${path}`
}

export function getCloudinaryPath(path: string) {
  return path
    .replace('https://admin.nohe.ch', 'nohe-admin')
    .replace('https://cms.blinkdrive.ch/assets', 'cms')
    .replace('http://127.0.0.1:8000/assets', 'cms')
    .replace(
      'https://s3.eu-central-1.amazonaws.com/admin.storage.blnk.ch',
      's3'
    )
    .replace('https://s3.blinkdrive.ch', 'storage')
    .replace('https://admin.blinkdrive.ch', 'admin')
}

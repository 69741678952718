import { Fragment } from 'react'
import reactStringReplace from 'react-string-replace'

export default function GradientText({
  children,
  highlight = true,
}: {
  children: string
  highlight?: boolean
}) {
  const gradients = [
    'bg-gradient-900',
    'bg-gradient-800',
    'bg-gradient-700',
    'bg-gradient-600',
  ]

  let index = 0

  return (
    <Fragment>
      {reactStringReplace(children, /\[([^\[\]]+)\]/gi, (match) => {
        if (!highlight) {
          return <Fragment>{match}</Fragment>
        }

        return (
          <span
            className={`${
              gradients[index++ % gradients.length]
            } box-decoration-break bg-clip-text pt-1 text-transparent`}
          >
            {match}
          </span>
        )
      }).map((element, index) => (
        <Fragment key={index}>{element}</Fragment>
      ))}
    </Fragment>
  )
}

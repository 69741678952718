import Image, { ImageProps } from 'next/image'
import { createElement } from 'react'
import { ImageType } from '../../@types/statamic'
import { Optional } from '../../@types/util'
import loader, { blurImage, getCloudinaryPath } from '@/src/lib/imageLoader'

export const illustrationBackgrounds = {
  'g-300': 'bg-gradient-300',
  'g-600': 'bg-gradient-600',
}

export type PictureProps = Omit<
  Optional<
    ImageType,
    | 'is_image'
    | 'id'
    | 'focus_css'
    | 'is_illustration'
    | 'illustration_background'
  >,
  'permalink'
> &
  Omit<ImageProps, 'src' | 'width' | 'height' | 'loader'> & {
    permalink?: ImageProps['src'] | ImageType['permalink']
  }

export default function Picture({
  permalink,
  is_image: _is_image = true,
  focus_css,
  blurDataURL,
  placeholder,
  fill,
  width,
  height,
  last_modified,
  is_illustration = false,
  ratio: _ratio,
  size_bytes: _size_bytes,
  orientation: _orientation,
  id = 'unknown',
  ...props
}: PictureProps) {
  if (!permalink) {
    throw new Error(`Asset for id ${id} has no link`)
  }

  let newPath = permalink
  if (
    !blurDataURL &&
    typeof permalink === 'string' &&
    !permalink.startsWith('/')
  ) {
    newPath = getCloudinaryPath(permalink)
    blurDataURL = blurImage(newPath)
    placeholder = 'blur'
  }

  let newProps: ImageProps = {
    src: last_modified ? `${newPath}?${encodeURI(last_modified)}` : newPath,
    objectPosition: focus_css,
    blurDataURL,
    placeholder,
    fill,
    ...props,
    loader: typeof permalink === 'string' ? loader : undefined,
    quality: is_illustration ? 85 : props.quality,
  }

  if (!fill) {
    newProps = { width, height, ...newProps }
  }

  return createElement(Image, newProps)
}

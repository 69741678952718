import { ImageType } from '../../@types/statamic'
import Picture, {
  illustrationBackgrounds,
  PictureProps,
} from '../elements/Picture'
import styles from '/src/css/Masks.module.css'

export default function CompactHeaderVariations({
  images,
}: {
  images: (ImageType | PictureProps)[]
}) {
  const image = images[0]
  if (image.is_illustration) {
    return <SingleIllustration image={image} />
  } else {
    return <SingleImage image={image} />
  }
}

function SingleImage({ image }: { image: ImageType | PictureProps }) {
  return (
    <div className="inset-0 -mx-4 md:absolute md:mx-0 md:pl-24">
      <div
        className={`aspect-h-2 aspect-w-3 relative w-full md:aspect-h-1 md:aspect-w-1`}
      >
        <div>
          <svg
            aria-hidden="true"
            className="absolute left-[85%] top-[120%] -z-10 w-[18%] md:bottom-[6%] md:left-0 md:top-auto md:w-1/2"
            viewBox="0 0 158 155"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              opacity="0.5"
              d="M138.447 28.9544C103.024 -7.25606 64.1952 -7.3399 29.3275 16.7309C-4.81746 41.366 -6.80473 89.6957 16.3199 125.238C39.4446 160.78 90.9331 160.592 122.368 141.034C153.803 121.477 173.871 65.1649 138.447 28.9544Z"
              fill="url(#paint0_linear_1896_9981)"
            />
            <defs>
              <linearGradient
                id="paint0_linear_1896_9981"
                x1="51.3641"
                y1="15.8074"
                x2="121.126"
                y2="151.98"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#D6DCFF" />
                <stop offset="1" stopColor="#EEF1FF" />
              </linearGradient>
            </defs>
          </svg>

          <svg
            aria-hidden="true"
            className="absolute right-[10%] top-[40%] w-3/4 md:hidden"
            viewBox="0 0 445 439"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              opacity="0.5"
              d="M391.544 82.4217C290.65 -20.6551 180.058 -20.8938 80.7473 47.6263C-16.5055 117.752 -22.1657 255.328 43.6986 356.502C109.563 457.676 256.214 457.141 345.748 401.469C435.282 345.796 492.438 185.499 391.544 82.4217Z"
              fill="url(#paint0_linear_1896_11704)"
            />
            <defs>
              <linearGradient
                id="paint0_linear_1896_11704"
                x1="143.512"
                y1="44.9975"
                x2="342.03"
                y2="432.719"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#D6DCFF" />
                <stop offset="1" stopColor="#EEF1FF" />
              </linearGradient>
            </defs>
          </svg>

          <svg
            width="56"
            height="55"
            viewBox="0 0 56 55"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="absolute bottom-[15%] right-0 hidden md:block"
          >
            <path
              opacity="0.5"
              d="M49.273 10.3262C36.5762 -2.58777 22.659 -2.61767 10.1615 5.96685C-2.07709 14.7526 -2.78939 31.9887 5.49915 44.6643C13.7877 57.3399 32.2426 57.2728 43.5099 50.2979C54.7771 43.323 61.9698 23.2401 49.273 10.3262Z"
              fill="url(#paint0_linear_3158_51311)"
            />
            <defs>
              <linearGradient
                id="paint0_linear_3158_51311"
                x1="18.06"
                y1="5.6375"
                x2="42.8666"
                y2="54.3026"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#D6DCFF" />
                <stop offset="1" stopColor="#EEF1FF" />
              </linearGradient>
            </defs>
          </svg>

          <div
            className={`absolute inset-0 bg-gradient-800 md:bottom-[20%] md:top-[5%] ${styles['mask-8']} ${styles['mask-12']} ${styles['mask']}`}
          >
            <Picture
              priority
              {...image}
              sizes="(min-width: 768px) 50vw, 100vw"
              fill
              className="object-cover"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

function SingleIllustration({ image }: { image: ImageType | PictureProps }) {
  return (
    <div className="inset-0 -mx-4 md:absolute md:mx-0 md:pl-24">
      <div className="w-full xl:w-3/5">
        <div
          className={`aspect-h-2 aspect-w-3 relative md:aspect-h-1 md:aspect-w-1`}
        >
          <div>
            <svg
              aria-hidden="true"
              className="absolute left-[85%] top-[120%] w-[18%] md:-bottom-1/4 md:left-[-10%] md:top-auto md:w-1/2"
              viewBox="0 0 158 155"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                opacity="0.5"
                d="M138.447 28.9544C103.024 -7.25606 64.1952 -7.3399 29.3275 16.7309C-4.81746 41.366 -6.80473 89.6957 16.3199 125.238C39.4446 160.78 90.9331 160.592 122.368 141.034C153.803 121.477 173.871 65.1649 138.447 28.9544Z"
                fill="url(#paint0_linear_1896_9981)"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_1896_9981"
                  x1="51.3641"
                  y1="15.8074"
                  x2="121.126"
                  y2="151.98"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#D6DCFF" />
                  <stop offset="1" stopColor="#EEF1FF" />
                </linearGradient>
              </defs>
            </svg>

            <svg
              aria-hidden="true"
              className="absolute right-[10%] top-[40%] w-3/4 md:-bottom-1/4 md:right-[-30%] md:top-[-40%]"
              viewBox="0 0 445 439"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                opacity="0.5"
                d="M391.544 82.4217C290.65 -20.6551 180.058 -20.8938 80.7473 47.6263C-16.5055 117.752 -22.1657 255.328 43.6986 356.502C109.563 457.676 256.214 457.141 345.748 401.469C435.282 345.796 492.438 185.499 391.544 82.4217Z"
                fill="url(#paint0_linear_1896_11704)"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_1896_11704"
                  x1="143.512"
                  y1="44.9975"
                  x2="342.03"
                  y2="432.719"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#D6DCFF" />
                  <stop offset="1" stopColor="#EEF1FF" />
                </linearGradient>
              </defs>
            </svg>

            <div
              className={`absolute inset-0 ${
                image.illustration_background
                  ? illustrationBackgrounds[
                      image.illustration_background?.value
                    ]
                  : 'bg-gradient-600'
              } ${styles['mask-8']} ${styles['mask-11']} ${styles['mask']}`}
            >
              <Picture priority {...image} fill className="object-cover" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

import { createElement, ReactHTML } from 'react'
import GradientText from './GradientText'

const availableStyleClasses = {
  1: {
    className: 'font-bold md:text-xl [text-wrap:balance]',
    as: 'h1',
  },
  header: {
    className:
      'font-extrabold text-7xl md:text-20xl lg:text-35xl [text-wrap:balance]',
    as: 'h2',
  },
  headerCompact: {
    className:
      'font-extrabold text-7xl md:text-20xl lg:text-25xl [text-wrap:balance]',
    as: 'h2',
  },
  bigH1: {
    className: 'font-extrabold text-4xl md:text-20xl [text-wrap:balance]',
    as: 'h1',
  },
  2: {
    className: 'font-extrabold text-4xl md:text-20xl [text-wrap:balance]',
    as: 'h2',
  },
  3: {
    className: 'font-extrabold text-2xl md:text-10xl [text-wrap:balance]',
    as: 'h3',
  },
  4: {
    className: 'font-extrabold text-lg md:text-4xl',
    as: 'h4',
  },
  5: {
    className: 'font-bold text-lg md:text-xl',
    as: 'h5',
  },
}
export default function Title({
  style,
  as,
  className = '',
  children,
}: {
  style: keyof typeof availableStyleClasses
  as?: keyof ReactHTML
  className?: string
  children: string
}) {
  const styleObject = availableStyleClasses[style]

  return createElement(
    as || styleObject.as,
    Object.assign(
      {},
      {
        className: `${className} ${styleObject.className}`,
      }
    ),
    <GradientText>{children}</GradientText>
  )
}

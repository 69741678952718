import classNames from 'classnames'
import {
  ComponentPropsWithoutRef,
  createElement,
  ElementType,
  ForwardedRef,
  forwardRef,
  ReactElement,
  ReactNode,
} from 'react'

const DEFAULT_BUTTON_TAG = 'button'

type ColorStyle = typeof availableColorStyles

export type ButtonProps = Parameters<typeof Button>[0]

const availableColorStyles = {
  primary: {
    default: 'bg-gradient-600 hover:bg-gradient-950 text-white',
    active: 'bg-blue-150 text-white',
    success: 'bg-green-400 text-white',
    error: 'bg-pink-500 text-white',
  },
  whatsapp: {
    default: 'bg-green-600 hover:bg-green-700  text-white',
  },
  white: {
    default: 'bg-white text-purple-700 hover:bg-gradient-300',
    active: 'bg-white text-purple-700',
  },
  border: {
    default: 'text-purple-700 border-2 border-purple-700 hover:bg-blue-100',
  },
  'border-white': {
    default: 'text-white border-2 border-white',
  },
  transparent: {
    default: 'bg-none text-purple-700 hover:text-purple-500',
  },
  'transparent-white': {
    default: 'bg-none text-white hover:text-blue-150',
  },
}

const availableSizeStyles = {
  none: 'text-sm',
  normal: 'pb-[11px] pt-3 px-5 text-sm !leading-[21px]',
  small: 'px-5 pb-1.5 pt-[7px] text-sm !leading-[21px]',
}

const DEFAULT_TYPE = 'primary' as const

function Button<
  Style extends keyof ColorStyle = typeof DEFAULT_TYPE,
  TAG extends ElementType = typeof DEFAULT_BUTTON_TAG,
>(
  {
    as,
    style = DEFAULT_TYPE as Style,
    size = 'normal',
    type = 'button',
    block = false,
    status = 'default',
    children,
    ...props
  }: {
    as?: TAG
    style?: Style
    size?: keyof typeof availableSizeStyles
    type?: 'submit' | 'button'
    status?: keyof ColorStyle[Style]
    block?: boolean
    children?: ReactNode
  } & Omit<ComponentPropsWithoutRef<TAG>, 'style'>,
  ref: ForwardedRef<TAG>
): ReactElement {
  const component = as || DEFAULT_BUTTON_TAG

  const componentProps = {
    className: classNames(
      props.className,
      availableColorStyles[style][status] as string,
      availableSizeStyles[size],
      { 'block w-full': block, 'inline-block': !block },
      'transition-colors duration-150 font-bold text-center rounded-full leading-none hover:cursor-pointer hover:disabled:cursor-not-allowed'
    ),
    type: component === DEFAULT_BUTTON_TAG ? type : undefined,
  }

  return createElement(
    component,
    Object.assign({}, { ...props, ref: ref }, componentProps),
    children
  )
}

export default forwardRef(Button)

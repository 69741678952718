import classNames from 'classnames'
import { ComponentPropsWithoutRef } from 'react'
import { replaceGradient } from '@/src/lib/replaceGradient'
import { replaceLink } from '@/src/lib/replaceLink'

export const textSizes = {
  xl: 'md:prose-xl',
  lg: 'md:prose-lg',
  base: '',
  allBase: 'prose-base',
  sm: 'prose-sm md:prose-base',
  allSm: 'prose-sm',
}

export const textColors = {
  default:
    'prose-headings:text-blue-900 prose-p:text-blue-900 prose-a:text-purple-600 hover:prose-a:text-purple-800',
  white:
    'prose-headings:text-white prose-p:text-white prose-a:!underline prose-a:text-white',
}

export default function Content({
  content,
  className,
  textSize = 'lg',
  textColor = 'default',
  ...props
}: {
  content?: string
  textSize?: keyof typeof textSizes
  textColor?: keyof typeof textColors
} & Omit<ComponentPropsWithoutRef<'div'>, 'style'>) {
  return (
    <div
      {...props}
      className={classNames([
        className,
        textSizes[textSize],
        textColors[textColor],
        'prose prose-headings:font-extrabold prose-h1:text-4xl prose-h2:text-4xl prose-h3:text-2xl prose-h4:text-lg prose-p:font-medium prose-a:font-bold prose-a:no-underline prose-strong:font-bold prose-strong:text-current md:prose-h1:text-20xl md:prose-h2:text-20xl md:prose-h3:text-10xl md:prose-h4:text-4xl',
        // custom table styling
        'prose-table:text-lg prose-tr:border-0 odd:prose-tr:bg-gradient-100 even:prose-tr:bg-white prose-th:px-6 prose-th:py-5 first:prose-th:rounded-l-[5px] last:prose-th:rounded-r-[5px] prose-td:!px-6 prose-td:!py-5 first:prose-td:rounded-l-[5px] last:prose-td:rounded-r-[5px]',
      ])}
      dangerouslySetInnerHTML={{
        __html: content ? replaceLink(replaceGradient(content)) : '',
      }}
    />
  )
}

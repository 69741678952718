const gradients = [
  'bg-gradient-900',
  'bg-gradient-800',
  'bg-gradient-700',
  'bg-gradient-600',
]

export function replaceGradient(content: string): string {
  let index = 0
  return content.replace(
    /\[([^\[\]]+)\]/g,
    `<span class="${
      gradients[index++ % gradients.length]
    } box-decoration-break bg-clip-text  text-transparent pt-1">$1</span>`
  )
}

import { cloneElement, ReactElement } from 'react'

export default function Icon({
  className,
  label,
  icon,
}: {
  className: string
  label?: string
  icon: ReactElement
}) {
  const newIcon = cloneElement(icon, { className: 'h-full w-full' })

  return (
    <span className={`inline-block ${className}`}>
      {label && <span className="sr-only">{label}</span>} {newIcon}
    </span>
  )
}

import classNames from 'classnames'
import { ReactElement, ReactNode } from 'react'

type ContainerProps = {
  children: ReactNode
  fullPageOnMobile?: boolean
  fullPage?: boolean
  className?: string
}

export default function Container({
  children,
  fullPageOnMobile = false,
  fullPage = false,
  className = '',
}: ContainerProps): ReactElement {
  return (
    <div
      className={classNames([
        'mx-auto',
        {
          'px-5': !fullPageOnMobile && !fullPage,
          'md:px-6 lg:max-w-container': !fullPage,
        },
        className,
      ])}
    >
      {children}
    </div>
  )
}
